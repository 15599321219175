import React from 'react';
import './ICC.css';

const ICC = () => {
    return (
        <div>
            <h1>QJMotor Benelux</h1>
        </div>
    );
}

export default ICC;
