import React from 'react';
import './UICardWrapper.css';
import UICard from '../UICard/UICard';

const UICardWrapper = ({ cards }) => {
    return (
        <div className="card-wrapper">
            {cards.map((card, index) => (
                <UICard
                    key={index}
                    title={card.title}
                    imageUrl={card.imageUrl}
                    spanEducation={card.spanEducation}
                    spanLevel={card.spanLevel}
                    spanYears={card.spanYears}
                    description={card.description}
                />
            ))}
        </div>
    );
};

export default UICardWrapper;