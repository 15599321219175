import React from 'react';
import './Resume.css';
import Icon2015 from "../../img/icons/2015.png";
import Icon2016 from "../../img/icons/2016.png";
import Icon2020 from "../../img/icons/2020.png";
import Icon2023 from "../../img/icons/2023.png";
import Persona from "../../img/Manon_Resume.png";
import ResumeBG from "../../img/resume_bg.png";
import Banner from "../../components/Banner/Banner";
import UICardWrapper from "../../components/UICardWrapper/UICardWrapper";
import Quotes from "../../components/Quotes/Quotes";
import HeroBanner from "../../components/HeroBanner/HeroBanner";


const Resume = () => {
    const background ={
        primary: '#e91fa8',
        primaryGradient2: '#e91fa8',
        primaryGradient1: '#b9dfee'
    };

    return (
        <div className="resume">
            <HeroBanner
                background={background}
                ProfilePicture={Persona}
                Header="Resume Manon"
                Subtitle="An overview of the previous skills I've learned during my academic career."
                Link1="/work"
                CTA1="Projects"
                Link2="/about"
                CTA2="About"
            />
            <div className="spacer"></div>
            <Quotes
                optionalHeading="Work Profile"
                Heading=" | Education"
                Subtitle="This section displays the different educations that were followed within the academic career. "
            />
            <div className="spacer"></div>
            <UICardWrapper
                cards={[
                        {
                            title: "Veterinary Assistant",
                            imageUrl: Icon2015,
                            spanEducation: "Discontinued",
                            spanYears: "1",
                            description: "Discovered passion for ICT during this education, switched study."
                        },
                    {
                        title: "Interaction Design",
                        imageUrl: Icon2016,
                        spanEducation: "Achieved",
                        spanYears: "4",
                        description: "Study interaction and multimedia design at Grafisch Lyceum Utrecht completed."
                    },
                    {
                        title: "ICT & Media",
                        imageUrl: Icon2020,
                        spanEducation: "In Progress",
                        spanYears: "3.5 and counting",
                        description: "Bachelor at Fontys Hogescholen in Eindhoven, with one year of the excellence program in the pocket, it is now time for graduation!"
                    },
                    {
                        title: "Linneaus University",
                        imageUrl: Icon2023,
                        spanEducation: "Achieved",
                        spanYears: "0.5",
                        description: "Minor spent in Sweden to discover more about computational thinking in computer science. Discovered that I liked front-end more."
                    },
                    {
                        title: "Global Acting in IT",
                        imageUrl: Icon2023,
                        spanEducation: "Achieved",
                        spanYears: "0.5",
                        description: "Minor abroad which collaborated with multiple ethnicities and got acquainted with the fundamentals of data design, user experience, internet of things, artificial intelligence and cyber security."
                    }
                ]}
            />
            <div className="spacer-lg"></div>
            <Quotes
                optionalHeading="Work Profile"
                Heading=" | Range"
                Subtitle="This section displays the wide-ranging spanning across multiple creative and technical disciplines. I like to pursuit skills that keep you challenged. "
            />
            <div className="spacer"></div>
            <UICardWrapper
                cards={[
                    {
                        title: "Interaction Design",
                        spanLevel: "Mediocre"
                    },
                    {
                        title: "Photoshop",
                        spanLevel: "Mediocre"
                    },
                    {
                        title: "Illustrator",
                        spanLevel: "Mediocre"
                    },
                    {
                        title: "InDesign",
                        spanLevel: "Beginner"
                    },
                    {
                        title: "Figma",
                        spanLevel: "Beginner"
                    },
                    {
                        title: "Adobe XD",
                        spanLevel: "Mediocre"
                    }
                ]}
            />
            <div className="spacer"></div>
            <Banner
                Background={ResumeBG}
                Heading="View the made work"
                Subtitle="Watch the skills come to life."
                Link="/work"
                CTA="Work"
            />
            <div className="spacer"></div>
            <UICardWrapper
                cards={[
                    {
                        title: "Web Development",
                        spanLevel: "Mediocre"
                    },
                    {
                        title: "ReactJS",
                        spanLevel: "Beginner"
                    },
                    {
                        title: "Angular",
                        spanLevel: "Beginner"
                    },
                    {
                        title: "HTML",
                        spanLevel: "Advanced"
                    },
                    {
                        title: "CSS/Tailwind",
                        spanLevel: "Mediocre"
                    },
                    {
                        title: "JS",
                        spanLevel: "Beginner/Mediocre"
                    },
                    {
                        title: "WordPress",
                        spanLevel: "Mediocre"
                    },
                    {
                        title: "Kotlin",
                        spanLevel: "Beginner"
                    },
                    {
                        title: "SwiftUI",
                        spanLevel: "Beginner"
                    }
                    ]}
                />
            <div className="spacer-lg"></div>
            <Quotes
                optionalHeading="Work Profile"
                Heading=" | Experience"
                Subtitle="This page displays the versatile skill set that was achieved during her education, previous work experiences, and interests."
            />
            <div className="spacer"></div>
            <UICardWrapper
                cards={[
                    {
                        title: "Interaction Design",
                        spanLevel: "Mediocre"
                    },
                    {
                        title: "Web Development",
                        spanLevel: "Mediocre"
                    },
                    {
                        title: "Presenting",
                        spanLevel: "Mediocre"
                    },
                    {
                        title: "Documenting",
                        spanLevel: "Advanced"
                    },
                    {
                        title: "Problem Solving",
                        spanLevel: "Mediocre"
                    }
                    ]}
                />
            <div className="spacer-lg"></div>
        </div>
    );
}

export default Resume;
