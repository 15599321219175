import React from 'react';
import './LoopLanguage.css';
import HeadingDescription from "../../../components/HeadingDescription/HeadingDescription";
import Quotes from "../../../components/Quotes/Quotes";
import BlogSection from "../../../components/BlogSection/BlogSection";
import FeatureList from "../../../components/FeatureList/FeatureList";
import ImageGallery from "../../../components/ImageGallery/ImageGallery";
import LLBanner from "../../../img/projects/Loop/banner_loop.png";
import LLBanner1 from "../../../img/projects/Loop/LLBanner1.png";
import LLBanner2 from "../../../img/projects/Loop/LLBanner2.png";
import LL_Desktop1 from "../../../img/projects/Loop/website/Homepage.png";
import LL_Desktop2 from "../../../img/projects/Loop/website/About.png";
import LL_Desktop3 from "../../../img/projects/Loop/website/Docs.png";
import LL_Desktop4 from "../../../img/projects/Loop/website/Updates.png";
import LL_DesktopD1 from "../../../img/projects/Loop/website/HomeDark.png";
import LL_DesktopD2 from "../../../img/projects/Loop/website/AboutDark.png";
import LL_DesktopD3 from "../../../img/projects/Loop/website/DocsDark.png";
import LL_DesktopD4 from "../../../img/projects/Loop/website/UpdatesDark.png";
import LL_Tablet1 from "../../../img/projects/Loop/website/TabletHome.png";
import LL_Tablet2 from "../../../img/projects/Loop/website/TabletAbout.png";
import LL_Phone1 from "../../../img/projects/Loop/website/PhoneHOme.png";
import LL_Phone2 from "../../../img/projects/Loop/website/PhoneAbout.png";

const LoopLanguage = () => {
    return (
        <div>
            <div className="spacer-lg"></div>
            <Quotes
                Heading="Loop Language"
                Subtitle="This is a project started by two students from Fontys Hogeschool. They thought of Loop Language as an innovative way of bringing cloud tools to a 'Provider-less' programming language. This project is still in it's early days, and is not production ready. "
            />
            <div className="spacer"></div>
            <FeatureList
                Label1="Concept"
                Heading1="Defining the goal"
                Subtitle1="Loop Language is a programming language that's still in development, mainly for building cloud tools. Or as they like to call it; infrastructure with code."
                Label2="Design"
                Heading2="Visualizing"
                Subtitle2="The Unique Selling Points (USPs) of Loop Language should be displayed on the home page, showing the intent of the project, as well as giving others an option to contribute to the project. However, since it's still in the early stages, the goal is more of a showcase."
                Label3="Development"
                Heading3="Building the website"
                Subtitle3="There was already a website built by Loop in React, this determined the programming language it was built in. The framework that was used for the CSS was Bootstrap."
            />
            <div className="spacer-lg"></div>
            <BlogSection
                Heading="The Website"
                Paragraph="
                The delivered website was created with the help of React and Bootstrap. The website is meant as a showcase for Loop Language, and should bring people more understanding of what Loop Language is.
                "
                EndSentence="View the website and prototype on the link below."
                Image={LLBanner}
                link="https://youtu.be/6wsDBSSDN08"
                CTA="View Products"
            />
            <div className="spacer-lg"></div>

            <HeadingDescription
                Heading="Context Loop Language"
                Paragraph="
                    The previous website was built with React. At the time of development, this was my first project within React. For the website, the navigation bar was revised, just as well as the micro-animations within the responsiveness.
                "
                EndSentence="
                        This modern type-safe programming language has a website where the documentation can be found, just as well as the pros of using Loop Language. Visitors can also try out Loop before installing it with a live editor.
                    "
                Image1={LLBanner2}
                Image2={LLBanner1}
            />


            <div className="spacer"></div>
            <Quotes
                Heading="Delivered"
                Subtitle="For Loop, a prototype and website were delivered. The prototype was made within Adobe XD and the website within React."
            />
            <ImageGallery images={[LL_Desktop1, LL_Desktop2, LL_Desktop3, LL_Desktop4, LL_DesktopD1, LL_DesktopD2, LL_DesktopD3, LL_DesktopD4, LL_Tablet1, LL_Tablet2, LL_Phone1, LL_Phone2]} />
            <div className="spacer-lg"></div>
        </div>
    );
}

export default LoopLanguage;
