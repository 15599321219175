import React from 'react';
import './HeadingDescription.css';
const HeadingDescription = ({Heading, Paragraph, EndSentence, Image1, Image2}) => {
    return(
        <section className="bg-white dark:bg-gray-900 px-10">
            <div
                className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">{Heading}</h2>
                    <p>{Paragraph}</p>
                    <p className="mb-4 mb-4-text mt-8">{EndSentence}</p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8 md:mt-28">
                    <img className="w-full rounded-lg"
                         src={Image1}
                         alt="Project Picture Left" />
                        <img className="mt-4 w-full lg:mt-10 rounded-lg"
                             src={Image2}
                             alt="Project Picture Right" />
                </div>
            </div>
        </section>

    )
}

export default HeadingDescription;