import React from 'react';
import { Button } from "flowbite-react";
import './BlogSection.css';

const BlogSection = ({Heading, Paragraph, EndSentence, Image, link, CTA}) => {
    return (
        <section className="bg-slate-100 py-16 px-10">
            <div className="container mx-auto">
                <div
                    className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                    <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">{Heading}</h2>
                        <p>{Paragraph}</p>
                        <p className="mb-4 mb-4-text mt-6">{EndSentence}</p>
                        <a href={link} target="_blank">
                            <Button className="btn-blog">
                                <span className="text-1xl">{CTA}</span>
                        </Button>
                        </a>
                    </div>
                    <div className="w-full px-4">
                        <div className="flex justify-center">
                            <div className="w-full">
                                <img className="rounded-lg shadow-lg md:mt-10" src={Image} alt="Product Showcase" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogSection;
