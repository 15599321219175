import React from 'react';
import './About.css';
import Persona from '../../img/Manon_Personal.png';
import AboutManon_1 from '../../img/ManonAbout_1.png';
import AboutManon_2 from '../../img/ManonAbout_2.png';
import Jogging from '../../img/hobbies/Hobbies_Jogging.png';
import Fitness from '../../img/hobbies/Hobbies_Fitness.png';
import Strength from '../../img/hobbies/Hobbies_Power.png';
import Squash from '../../img/hobbies/Hobbies_Squash.png';
import Friends from '../../img/hobbies/Hobbies_Friends.png';
import Hacking from '../../img/hobbies/Hobbies_Hacken.png';
import HeroBanner from "../../components/HeroBanner/HeroBanner";
import UICardWrapper from "../../components/UICardWrapper/UICardWrapper";
import Quotes from "../../components/Quotes/Quotes";
import HeadingDescription from "../../components/HeadingDescription/HeadingDescription";



const About = () => {
    const background ={
        primary: '#722AE6',
        primaryGradient2: '#722AE6',
        primaryGradient1: '#E4B5CB'
    };

    return (
        <div className="home">
            <div className="home-wrapper">
                <HeroBanner
                    background={background}
                    ProfilePicture={Persona}
                    Header="About Manon"
                    Subtitle="Who is the person behind the work, and what are the aspirations."
                    Link1="/work"
                    CTA1="Projects"
                    Link2="/resume"
                    CTA2="Resume"
                />
            </div>
            <div className="spacer"></div>
            <Quotes Heading="Traits" />
            <div className="mt-10"></div>
            <UICardWrapper cards={[
                {
                    title: "Graduation",
                    description: "2025"
                },
                {
                    title: "Ambition",
                    description: "Front-end Developer"
                },
                {
                    title: "Studied Years",
                    description: "8"
                }
            ]}
            />
            <div className="spacer"></div>
            <HeadingDescription
                Heading="Background"
                Paragraph="Hi! I'm Manon, 24 years old and living in the Netherlands. Previously I purchased a career in the veterinary sector, however, instead of finishing that study, I discovered my passion for web development. I started out in 2016 by following tutorials within W3Schools.com."
                EndSentence="And before I knew it, that was the start of my current career path. I am always curious to learn more and within this dynamic sector, that is bound to happen continuously. As well as learning, travelling is also a passion of mine. In 2023, I followed a semester abroad in Sweden to learn more about computational thinking, which also got me a temporary job as a Teaching Assistant in Data Analytics at Linneaus University, Växjö, Sweden."
                Image1={AboutManon_1}
                Image2={AboutManon_2}
            />

            <div className="h-10"></div>
            <Quotes Heading="Hobbies" />
            <div className="mt-10"></div>

            <UICardWrapper cards={[
                {
                    imageUrl:Squash,
                    title: "Squash",
                    description: "Currently, I mainly play for fun but sometimes also for competition in the Clubladder. "
                },
                {
                    imageUrl:Strength,
                    title: "Powerlifting",
                    description: "I just like picking up progressively heavier and heavier weights for fun."
                },
                {
                    imageUrl:Jogging,
                    title: "Running",
                    description: "Running is a fairly new hobby, this really helps with endurance and mental health."
                },
                {
                    imageUrl:Friends,
                    title: "Quality Time",
                    description: "Spending time with friends is also important to me to keep a healthy work-life balance. "
                },
                {
                    imageUrl:Hacking,
                    title: "Ethical Hacking",
                    description: "I'm an absolute beginner with ethical hacking, but I like to learn more about it with TryHackMe."
                },
                {
                    imageUrl:Fitness,
                    title: "Fitness",
                    description: "The ultimate challenge for your body. I like working out and challenging myself."
                },
            ]}
            />
            <div className="spacer-lg"></div>
        </div>

    );
}

export default About;
