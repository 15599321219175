import React from 'react';
import './QJMotorBenelux.css';
import HeadingDescription from "../../../components/HeadingDescription/HeadingDescription";
import Quotes from "../../../components/Quotes/Quotes";
import BlogSection from "../../../components/BlogSection/BlogSection";
import FeatureList from "../../../components/FeatureList/FeatureList";
import ImageGallery from "../../../components/ImageGallery/ImageGallery";
import QJImageOne from "../../../img/projects/QJ/QJMotor_Long1.png";
import QJImageTwo from "../../../img/projects/QJ/QJMotor_Long2.png";
import QJBanner from "../../../img/projects/QJ/qjmotor-banner.png";
import QJWebsiteHome from "../../../img/projects/QJ/website/Home-QJMOTOR-Benelux.png";
import QJWebsiteAbout from "../../../img/projects/QJ/website/Over-QJMotor-QJMOTOR-Benelux.png";
import QJWebsiteModel from "../../../img/projects/QJ/website/Modellen-QJMOTOR-Benelux.png";
import QJWebsitePDP from "../../../img/projects/QJ/website/SRT-800-SX-QJMOTOR-Benelux.png";
import QJWebsiteContact from "../../../img/projects/QJ/website/Contact-QJMOTOR-Benelux.png";
import QJWebsiteProefrit from "../../../img/projects/QJ/website/Proefrit-QJMOTOR-Benelux.png";

const QJMotorBenelux = () => {
    return (
        <div>
            <div className="spacer-lg"></div>
            <Quotes
                Heading="QJMotor Benelux"
                Subtitle="A project with a very strict deadline, that only left me a week to realize this project. For this project, it was important to look into existing QJMotors websites, and create a platform that was easily customizable and optionally editable by the employees of Motomondo B.V."
            />
            <div className="spacer"></div>
            <FeatureList
                Label1="Concept"
                Heading1="Defining the goal"
                Subtitle1="Organizing the information architecture of the website, in combination with the goal of the website and the vision of the stakeholder."
                Label2="Design"
                Heading2="Visualizing"
                Subtitle2="Based on other websites of QJMotor, and their corporate identity, a design file in Figma was set up to act as a mood board and inspiration for the design of the website. "
                Label3="Development"
                Heading3="Building the website"
                Subtitle3="The needs of the stakeholder changed pretty often, for this a quick and highly customizable was needed and met with the usage of CMS; Wordpress."
            />
                <div className="spacer-lg"></div>
                <BlogSection
                    Heading="The Website"
                    Paragraph="The delivered website works with the content of Wordpress posts, making it easy for employees with no-code/low-code experience to maintain, as well as release new features/make quick changes."
                    EndSentence="View the delivered project on the link below."
                    Image={QJBanner}
                    link="https://qjmotor-benelux.com/"
                    CTA="View Site"
                />
            <div className="spacer-lg"></div>

            <HeadingDescription
                Heading="Background QJMotor Benelux"
                Paragraph="
                From 1 May 2024, importer MotoMondo will represent the - for the Benelux new - top brand QJMOTOR. Part of the leading Geely group, QJMOTOR is one of the largest motorbike manufacturers."
                EndSentence="Founded in 1985, QJMOTOR produces over a million motorbikes and motor scooters a year. They also develop and produce their own engine blocks. The current production processes comply with the strict IATF 16949 quality management standard, a certification used worldwide in the automotive industry."
                Image1={QJImageTwo}
                Image2={QJImageOne}
            />


            <div className="spacer"></div>
            <Quotes
                Heading="Delivered"
                Subtitle="Wordpress website for QJMotor Benelux. These are screenshots from the delivered website, click on them to view more details."
            />
            <ImageGallery images={[QJWebsiteHome, QJWebsiteAbout, QJWebsiteModel, QJWebsitePDP, QJWebsiteContact, QJWebsiteProefrit]} />
            <div className="spacer-lg"></div>
        </div>
    );
}

export default QJMotorBenelux;
