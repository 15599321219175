import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ImageGallery = ({ images }) => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const openLightbox = (index) => {
        setPhotoIndex(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };

    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:py-16 lg:px-6">
                <div className="grid grid-cols-2 gap-4 mt-8 md:grid-cols-4">
                    {images.map((image, index) => (
                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                        <img
                            key={index}
                            className="w-full rounded-lg cursor-pointer hover:opacity-80 transition-opacity duration-300"
                            src={image}
                            alt={`Project Picture ${index + 1}`}
                            onClick={() => openLightbox(index)}
                        />
                    ))}
                </div>
            </div>
            {lightboxOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            )}
        </section>
    );
};

export default ImageGallery;
