import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Persona from '../../img/Manon_Portfolio.png';
import WebDesignIcon from '../../img/webdesign-icon.png';
import WebDevelopIcon from '../../img/webdevelopment-icon.png';
import WebOtherIcon from '../../img/interests-icon.png';
import BannerBG from '../../img/banner.png';
import UICardWrapper from "../../components/UICardWrapper/UICardWrapper";
import HeroBanner from "../../components/HeroBanner/HeroBanner";
import Banner from "../../components/Banner/Banner";



const Home = () => {
    const background ={
        primary: '#FC5C7D',
        primaryGradient2: '#FC5C7D',
        primaryGradient1: '#6A82FB'
    };

    return (
        <div className="home">
            <div className="home-wrapper">
                <HeroBanner
                    background={background}
                    ProfilePicture={Persona}
                    Header="Manon Theresa"
                    Subtitle="Webdesign and front end development enthusiast."
                    Link1="/work"
                    CTA1="Projects"
                    Link2="/resume"
                    CTA2="Resume"
                />

            </div>
            <div className="spacer"></div>
            <div className="quotes">
                <h1>Design, Development, and passion for ICT.</h1>
                <p>Continuously looking around for ways to improve or extend my current skillset.</p>
            </div>
            <div className="spacer"></div>
            <UICardWrapper
                cards={[
                    {
                        title: "Web Design",
                        imageUrl: WebDesignIcon,
                        description: "Intrigued by creating a seamless user experience and sleek user interface."
                    },
                    {
                        title: "Web Development",
                        imageUrl:WebDevelopIcon,
                        description: "Enthusiast developer inspired by JavaScript frameworks and new technologies."
                    },
                    {
                        title: "Other Interests",
                        imageUrl: WebOtherIcon,
                        description: "Interested in cyber security, social media, marketing, and organizational processes."
                    },
                ]}
            />
            <div className="spacer-lg"></div>
            <Banner
                Background={BannerBG}
                Heading="Watch the development of the skillset."
                Subtitle="Within the followed academic journey(s)!"
                Link="/resume"
                CTA="Resume"
            />
            <div className="spacer-lg"></div>
            <div className="section">
                <h1 className="quotes-heading">Empathize, Define, Ideate, Prototype, and Test.</h1>
                <p className="quotes-subtitle">And then Realize, Iterate, and Repeat!</p>
                <button className="section-btn">
                    <Link to="/work">Portfolio</Link>
                </button>
            </div>
            <div className="spacer-lg"></div>
        </div>
    );
}

export default Home;
