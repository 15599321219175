import React from 'react';
import { Button, Card } from "flowbite-react";
import { Link } from 'react-router-dom';
import './ProjectCard.css';

const ProjectCard = ({ coverImage, title, shortDescription, link, CTA }) => {
    if (!CTA) {
        CTA = "View Project";
    }

    return (
        <Link to={link} className="project-card-link">
            <Card
                className="max-w-sm"
                imgAlt="Meaningful alt text for an image that is not purely decorative"
                imgSrc={coverImage}
            >
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {title}
                </h5>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                    {shortDescription}
                </p>
                <Button className="btn-project">
                    <Link to={link}>{CTA}</Link>
                </Button>
            </Card>
        </Link>
    );
}

export default ProjectCard;
