import React from 'react';
import './DeltaFHICTVideo.css';

const DeltaFHICTVideo = () => {
    return (
        <div>
            {/* https://www.youtube.com/watch?v=_Kl2SxNfjA8 */}

            <h1>Delta FHICT Video</h1>
        </div>
    );
}

export default DeltaFHICTVideo;
