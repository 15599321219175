import React from 'react';
import './Work.css';
import Persona from "../../img/Manon_Work.png";
import TheBigFive from "../../img/projects/thebigfive.png";
import ICC from "../../img/projects/internationalcleaningcompany.png";
import QJ from "../../img/projects/qjmotor-benelux.png";
import LoopLang from "../../img/projects/looplanguage.png";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import HeroBanner from "../../components/HeroBanner/HeroBanner";


const Work = () => {
    const background ={
        primary: '#FF512F',
        primaryGradient2: '#FF512F',
        primaryGradient1: '#DD2476'
    };

    return (
        <div className="work">
                <HeroBanner
                    background={background}
                    ProfilePicture={Persona}
                    Header="#WorkIT"
                    Subtitle="An overview of the previous accomplishments made."
                    Link1="/resume"
                    CTA1="Resume"
                    Link2="/about"
                    CTA2="About"
                />
            <div className="spacer"></div>
            <div className="quotes">
                <h1>Projects</h1>
                <p>This section displays the different projects that were made within the different IT disciplines. </p>
            </div>
            <div className="spacer"></div>
            <div className="work-projects-wrapper">
                <ProjectCard
                coverImage={QJ}
                title="QJMotor Benelux"
                shortDescription="A newly arrived motor brand in the Benelux, represented by Motomondo BV. Before the press release went live, a website was needed which was created in this project."
                link="/work/qj-motor-benelux"
                />
                <ProjectCard
                coverImage={ICC}
                title="International Cleaning Company"
                shortDescription="Creating an onboarding process for the registration of new cleaners, where their skills are evaluated and a cleaner can find new jobs in their nearby surroundings."
                link="/work"
                CTA="In Progress"
                />
                <ProjectCard
                coverImage={TheBigFive}
                title="Global Acting in IT"
                shortDescription="The Big Five started a project that contributes to the Sustainable Development Goals, creating a more equal workplace for people with a hearing impairment."
                link="/work/global-acting-in-it"
                />
                <ProjectCard
                    coverImage={LoopLang}
                    title="Loop Language"
                    shortDescription="A project created by Delta students from Fontys Hogeschool. The idea is for it to be infrastructure with code, which handles all different aspects of virtual infra."
                    link="/work/loop-language"
                />
            </div>
            <div className="spacer"></div>
        </div>
    );
}

export default Work;
