import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TitleManager = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        const titles = {
            '/': 'Home',
            '/work': 'Work',
            '/work/qj-motor-benelux': 'QJMotor Benelux',
            '/work/international-cleaning-company': 'ICC',
            '/work/global-acting-in-it': 'Global Acting in IT',
            '/work/loop-language': 'Loop Language',
            '/work/delta-fhict-video': 'Delta FHICT Video',
            '/resume': 'Resume',
            '/about': 'About'
        };
        document.title = `Manon Theresa | ${titles[location.pathname] || 'Not Found'}`;
    }, [location.pathname]);

    return children;
};

export default TitleManager;
