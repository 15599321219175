import React from 'react';
import { useLocation } from 'react-router-dom';

const Breadcrumb = ({ paths }) => {
    const { pathname } = useLocation();
    const isAboutOrResume = pathname === '/about' || pathname === '/resume';
    const isProjectPage = pathname.startsWith('/work/');
    const aboutOrResumeBreadcrumbPath = isAboutOrResume ? paths.filter(path => path.url === pathname || path.url === '/') : [];
    const projectBreadcrumbPath = isProjectPage ? paths.filter(path => path.url === '/' || path.url === '/work' || path.url === pathname) : [];
    const regularBreadcrumbPath = !isAboutOrResume && !isProjectPage ? paths.filter(path => path.url === pathname || path.url === '/') : [];
    const breadcrumbPath = [...aboutOrResumeBreadcrumbPath, ...projectBreadcrumbPath, ...regularBreadcrumbPath];

    return (
        <nav className="flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                {breadcrumbPath.map((path, index) => (
                    <li key={index} className="inline-flex items-center">
                        {index !== 0 && (
                            <div className="flex items-center">
                                <svg className="rtl:rotate-180 block w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                                </svg>
                                <a href={path.url} className="ms-1 text-sm font-medium text-gray-700 hover:text-pink-400 md:ms-2 dark:text-gray-400 dark:hover:text-white">{path.name}</a>
                            </div>
                        )}
                        {index === 0 && (
                            <a href={path.url} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-pink-400 dark:text-gray-400 dark:hover:text-white">
                                <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                                </svg>
                                {path.name}
                            </a>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumb;
