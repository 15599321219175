import React, { useMemo } from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './HeroBanner.css';

const HeroBanner = React.memo(({ background, ProfilePicture, Header, Subtitle, Link1 = "", CTA1 = "", Link2 = "", CTA2 = "" }) => {
    const { primary = "#ffffff", primaryGradient2 = "#ffcc00", primaryGradient1 = "#ff6666" } = background || {};

    // eslint-disable-next-line jsx-a11y/img-redundant-alt
    const profilePictureJSX = useMemo(() => <img src={ProfilePicture} alt="Profile picture banner" className="profile-picture" />, [ProfilePicture]);

    return (
        <div className="banner-background" style={{ background: `linear-gradient(to bottom right, ${primary}, ${primaryGradient2}, ${primaryGradient1})` }}>
            <div className="banner-content">
                {profilePictureJSX}
                <div className="banner-text">
                    <h1>{Header}</h1>
                    <span>{Subtitle}</span>
                    {(Link1 && CTA1) || (Link2 && CTA2) ? (
                        <div className="btn-wrapper">
                            {Link1 && CTA1 && (
                                <button className="btn-1">
                                    <Link to={Link1}>{CTA1}</Link>
                                </button>
                            )}
                            {Link2 && CTA2 && (
                                <button className="btn-2">
                                    <a href={Link2}>{CTA2}</a>
                                </button>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
});

HeroBanner.propTypes = {
    background: PropTypes.object.isRequired,
    ProfilePicture: PropTypes.string.isRequired,
    Header: PropTypes.string.isRequired,
    Subtitle: PropTypes.string.isRequired,
    Link1: PropTypes.string,
    CTA1: PropTypes.string,
    Link2: PropTypes.string,
    CTA2: PropTypes.string
};

export default HeroBanner;
