import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faAddressBook, faUser, faCompass } from '@fortawesome/free-regular-svg-icons';
import TitleManager from './components/TitleManager/TitleManager';
import Home from './pages/Home/Home';
import Breadcrumb from './components/Breadcrumb/Breadcrumb';
import Footer from './components/Footer/Footer';
import Work from './pages/Work/Work';
import QJMotorBenelux from './pages/Work/QJMotorBenelux/QJMotorBenelux';
import GlobalActing from './pages/Work/GlobalActing/GlobalActing';
import ICC from './pages/Work/ICC/ICC';
import LoopLanguage from './pages/Work/LoopLanguage/LoopLanguage';
import Delta from './pages/Work/DeltaFHICTVideo/DeltaFHICTVideo';
import Resume from './pages/Resume/Resume';
import About from './pages/About/About';
import NotFound from './pages/NotFound/NotFound';
import Logo from './logo.svg';
import "./App.css";

function Navigation() {
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        if (showMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);

    const handleNavLinkClick = () => {
        setShowMenu(false);
    };

    return (
        <nav ref={menuRef} className={`sidebar ${showMenu ? 'show' : ''}`}>
            <div className="banner-wrapper">
                <button className={`toggle-menu ${showMenu ? 'opened' : ''}`} onClick={toggleMenu} aria-label="Main Menu">
                    <svg width="70" height="100" viewBox="0 0 100 100">
                        <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                        <path className="line line2" d="M 20,50 H 80" />
                        <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                    </svg>
                </button>
                <img src={Logo} alt="Logo Portfolio ManonTheresa" className="logo" />
            </div>
            <ul className="nav-menu">
                <li className="nav-list">
                    <NavLink to="/" className="nav-link" activeclassname="active" onClick={handleNavLinkClick}>
                        <FontAwesomeIcon icon={faCompass} className="nav-link-icon" />
                        Home
                    </NavLink>
                </li>
                <li className="nav-list">
                    <NavLink to="/work" className="nav-link" onClick={handleNavLinkClick}>
                        <FontAwesomeIcon icon={faCode} className="nav-link-icon" />
                        Work
                    </NavLink>
                </li>
                <li className="nav-list">
                    <NavLink to="/resume" className="nav-link" onClick={handleNavLinkClick}>
                        <FontAwesomeIcon icon={faAddressBook} className="nav-link-icon" />
                        Resume
                    </NavLink>
                </li>
                <li className="nav-list">
                    <NavLink to="/about" className="nav-link" onClick={handleNavLinkClick}>
                        <FontAwesomeIcon icon={faUser} className="nav-link-icon" />
                        About
                    </NavLink>
                </li>
                <li className="nav-list">
                    <a href="https://www.linkedin.com/in/manon-ykema-1708/" target="_blank" className="nav-link" onClick={handleNavLinkClick}>
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                </li>
            </ul>
        </nav>
    );
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
function App() {
    const paths = [
        { name: 'Home', url: '/' },
        { name: 'Work', url: '/work' },
        { name: 'QJMotor Benelux', url: '/work/qj-motor-benelux' },
        { name: 'ICC', url: '/work/international-cleaning-company' },
        { name: 'Global Acting in IT', url: '/work/global-acting-in-it' },
        { name: 'Smart Mobile PWA', url: '/work/smart-mobile-pwa' },
        { name: 'Smart Mobile Android', url: '/work/smart-mobile-android' },
        { name: 'Smart Mobile iOS', url: '/work/smart-mobile-ios' },
        { name: 'Loop Language', url: '/work/loop-language' },
        { name: 'Delta FHICT Video', url: '/work/delta-fhict-video' },
        { name: 'Resume', url: '/resume' },
        { name: 'About', url: '/about' }
    ];
    return (
        <Router>
            <TitleManager>
            <div>
                <ScrollToTop />
                <Navigation />
                <div className="content-wrapper">
                    <Breadcrumb paths={paths} />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/work" element={<Work />} />
                        <Route path="work/qj-motor-benelux" element={<QJMotorBenelux />} />
                        <Route path="work/international-cleaning-company" element={<ICC />} />
                        <Route path="work/global-acting-in-it" element={<GlobalActing />} />
                        <Route path="work/loop-language" element={<LoopLanguage />} />
                        <Route path="work/delta-fhict-video" element={<Delta />} />
                        <Route path="/resume" element={<Resume />} />
                        <Route path="/about" element={<About />} />
                        {/* Route for NotFound component */}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </div>
            <Footer />
            </TitleManager>
        </Router>
    );
}

export default App;
