import React from 'react';
import './Quotes.css';

const Quotes = ({optionalHeading, Heading, Subtitle}) => {
    return (
        <div className="quotes md:w-10/12">
            <h1>{optionalHeading && <span className="opacity-text">{optionalHeading}</span>}{Heading}</h1>
            {Subtitle && <p className="quotes-subtitle">{Subtitle}</p>}
        </div>
    );
};

export default Quotes;
