import React from 'react';
import PropTypes from 'prop-types';
import './UICard.css';

const UICard = ({ title, imageUrl, description, spanLevel, spanEducation, spanYears }) => {
    return (
        <div className="ui-card">
            {imageUrl && <img src={imageUrl} alt={title} className="ui-card-image" />}
            <div className="ui-card-content">
                <h2 className="ui-card-title">{title}</h2>
                {spanLevel && <span className="ui-card-level">{spanLevel}</span>}
                {spanEducation && <span className="ui-card-education">{spanEducation}</span>}
                {spanYears && <span className="ui-card-years">{spanYears}</span>}
                {description &&<p className="ui-card-description">{description}</p>}
            </div>
        </div>
    );
};

UICard.propTypes = {
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    spanEducation: PropTypes.string,
    spanYears: PropTypes.string,
    description: PropTypes.string
};

export default UICard;