import React from 'react';
import './Banner.css';

const Banner = ({Background, Heading, Subtitle, Link, CTA}) => {
    return (
        <div className="banner-bg" style={{ backgroundImage: `linear-gradient(to bottom, rgb(0 0 0 / 100%), rgb(0 0 0 / 0%)), url(${Background})` }}>
            <h1 className="banner-heading">{Heading}</h1>
            <p className="banner-subtitle">{Subtitle}</p>
            <button className="btn-1">
                <a href={Link}>{CTA}</a>
            </button>
        </div>
    );
};

export default Banner;
