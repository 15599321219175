import React from 'react';

const FeatureList = ({Label1, Heading1, Subtitle1, Label2, Heading2, Subtitle2, Label3, Heading3, Subtitle3}) => {
    return (
        <section className="bg-white dark:bg-gray-900 antialiased mx-10">

                <div
                    className="grid grid-cols-1 mt-12 text-center sm:mt-16 gap-x-20 gap-y-12 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="space-y-4">
        <span
            className="bg-gray-100 text-gray-900 text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
          {Label1}
        </span>
                        <h3 className="text-2xl font-bold leading-tight text-gray-900 dark:text-white">
                            {Heading1}
                        </h3>
                        <p className="text-lg font-normal text-gray-500 dark:text-gray-400">
                            {Subtitle1}
                        </p>
                    </div>

                    <div className="space-y-4">
        <span
            className="bg-gray-100 text-gray-900 text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
          {Label2}
        </span>
                        <h3 className="text-2xl font-bold leading-tight text-gray-900 dark:text-white">
                            {Heading2}
                        </h3>
                        <p className="text-lg font-normal text-gray-500 dark:text-gray-400">
                            {Subtitle2}
                        </p>
                    </div>

                    <div className="space-y-4">
        <span
            className="bg-gray-100 text-gray-900 text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
          {Label3}
        </span>
                        <h3 className="text-2xl font-bold leading-tight text-gray-900 dark:text-white">
                            {Heading3}
                        </h3>
                        <p className="text-lg font-normal text-gray-500 dark:text-gray-400">
                            {Subtitle3}
                        </p>
                    </div>
                </div>
        </section>
    );
};

export default FeatureList;
