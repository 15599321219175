import React from 'react';
import './NotFound.css';
import Persona from "../../img/ManonNotFound.png";
import HeroBanner from "../../components/HeroBanner/HeroBanner";


const NotFound = () => {
    const background ={
        primary: '#11998e',
        primaryGradient2: '#11998e',
        primaryGradient1: '#38ef7d'
    };

    return (
        <div className="not-found">
            <HeroBanner
                background={background}
                ProfilePicture={Persona}
                Header="Not Found"
                Subtitle="This link was either removed or incorrect. Check out these pages!"
                Link1="/resume"
                CTA1="Resume"
                Link2="/work"
                CTA2="Work"
            />
        </div>
    );
}

export default NotFound;
