import React from 'react';
import './GlobalActing.css';
import HeadingDescription from "../../../components/HeadingDescription/HeadingDescription";
import Quotes from "../../../components/Quotes/Quotes";
import BlogSection from "../../../components/BlogSection/BlogSection";
import FeatureList from "../../../components/FeatureList/FeatureList";
import ImageGallery from "../../../components/ImageGallery/ImageGallery";
import GABanner from "../../../img/projects/GlobalActing/GlobalActingBanner.png";
import VHABanner1 from "../../../img/projects/GlobalActing/VHA_Banner1.png";
import VHABanner2 from "../../../img/projects/GlobalActing/VHABanner2.png";
import VHA_Desktop1 from "../../../img/projects/GlobalActing/VHA_App.png";
import VHA_Desktop2 from "../../../img/projects/GlobalActing/VHA_AppSetting.png";
import VHA_Desktop3 from "../../../img/projects/GlobalActing/VHA_Loading.png";
import VHA_Desktop4 from "../../../img/projects/GlobalActing/VHA_Active.png";
import VHA_Web1 from "../../../img/projects/GlobalActing/VHA_Home.png";
import VHA_Web2 from "../../../img/projects/GlobalActing/VHA_About.png";
import VHA_Web3 from "../../../img/projects/GlobalActing/VHA_Download.png";
import VHA_Web4 from "../../../img/projects/GlobalActing/VHA_Contact.png";
import VHA_Web5 from "../../../img/projects/GlobalActing/VHA_Login.png";
import VHA_Web6 from "../../../img/projects/GlobalActing/VHA_NewAccount.png";
import VHA_Web7 from "../../../img/projects/GlobalActing/VHA_Account.png";
import VHA_Web8 from "../../../img/projects/GlobalActing/VHA_FAQ.png";
import VHA_Web9 from "../../../img/projects/GlobalActing/VHA_Help.png";
import VHA_Web10 from "../../../img/projects/GlobalActing/VHA_RB1.png";
import VHA_Web11 from "../../../img/projects/GlobalActing/VHA_RB2.png";
import VHA_Web12 from "../../../img/projects/GlobalActing/VHA_Improve.png";

const QJMotorBenelux = () => {
    return (
        <div>
            <div className="spacer-lg"></div>
            <Quotes
                Heading="Global Acting in IT"
                Subtitle="This is an international minor where international students work together, creating a multicultural environment. Universities participated from Belgium, Austria, Spain, and South Africa. The assignment for this minor was to create a project that would help with creating a more equal work environment for hearing impaired people."
            />
            <div className="spacer"></div>
                <FeatureList
                    Label1="Concept"
                    Heading1="Defining the goal"
                    Subtitle1="The concept had to be fitted within the sustainable development goal. For this, the focus was placed on accommodating hearing impaired people in the classroom, to give them an equal chance at the same education."
                    Label2="Design"
                    Heading2="Visualizing"
                    Subtitle2="For the visualization, we wanted to create an application that listens to the sound input on Teams, Zoom, or other channels that automatically shows the sign language (ASL) in a resizable overlaying screen."
                    Label3="Development"
                    Heading3="Building the application"
                    Subtitle3="The application was built with the help of C++ and Python. A website to promote the application was also made available, here the user can download the program and read more about why the program was instantiated."
                />
                <div className="spacer-lg"></div>
                <BlogSection
                    Heading="The Proof of Concept"
                    Paragraph="Our group, The Big Five, created a proof of concept (POC) with the help of Whisper, from OpenAI. In the POC it was based on the microphone input, the content was placed into a transcribed message and based on that the videos were displayed. However, the glossary for ASL was not yet implemented and is different per variation of sign language. "
                    EndSentence="View the proof of concept on the link below."
                    Image={GABanner}
                    link="https://youtu.be/FeFe_9uvadk"
                    CTA="View Products"
                />
                <div className="spacer-lg"></div>

                <HeadingDescription
                    Heading="Context Virtual Hearing Assistant"
                    Paragraph="
                    Within the dimensions of data design, user experience, the Internet of Things, Artificial Intelligence and Cyber security an application was set up.
                    Students with a hearing impairment have trouble with (digital) communication, creating social barriers as well as educational barriers, meaning they get the same information as hearing students, but are unable to absorb the same quantity due to the barrier.
                    Lipreading is a very difficult skill to master, most of the time ASL is used.
                "
                    EndSentence="
                    The different topics were given within different universities, each in a different country. This project was created due to lack of other ASL-accessible apps that'll help dissolve the educational barriers. However, even though the proof of concept was successful, the project was not finished.
                    "
                    Image1={VHABanner1}
                    Image2={VHABanner2}
                />


            <div className="spacer"></div>
            <Quotes
                Heading="Delivered"
                Subtitle="We delivered a POC and a website where the mission is described, together with the download page. When the app is downloaded and the user is logged in, it shows several other pages, such as 'Report A Bug' or a 'Help Us Improve'. This has been done since the deaf community has a lack of representation, and we wanted to make sure their suggestions were heard."
            />
            <ImageGallery images={[VHA_Desktop1, VHA_Desktop2, VHA_Desktop3, VHA_Desktop4, VHA_Web1, VHA_Web2, VHA_Web3, VHA_Web4, VHA_Web5, VHA_Web6, VHA_Web7, VHA_Web8, VHA_Web9, VHA_Web10, VHA_Web11, VHA_Web12]} />
            <div className="spacer-lg"></div>
        </div>
    );
}

export default QJMotorBenelux;
